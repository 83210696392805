<template>
  <div :class="showTable ? 'Ui-badges':''">
    <div v-if="showTable">
      <div class="tabel-box">
        <p class="all-crumbs">项目管理 > 我的客户</p>
        <div class="p-box">
          <p
            :class="tableVal == 2 ? 'in-p':''"
            @click="checkTable(2)">
            企业类
          </p>
          <p
            :class="tableVal == 1 ? 'in-p':''"
            @click="checkTable(1)">
            个人类
          </p>
        </div>
      </div>
      <div class="top-box">
        <el-button @click="add">新增客户</el-button>
        <div class="ipt-box">
          <el-input
            v-model="keyword"
            prefix-icon="el-icon-search"
            @keyup.enter.native="chekEnter"
            placeholder="请输入关键词">
          </el-input>
        </div>
      </div>
      <div class="form-box">
        <el-table :data="tableData" ref="table" style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            width="100">
          </el-table-column>
          <el-table-column prop="name" label="客户名称" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="contact" show-overflow-tooltip label="联系方式" width="200">
          </el-table-column>
          <el-table-column label="所在城市" show-overflow-tooltip width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.city">
                {{scope.row.province}} - {{scope.row.city}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="address" show-overflow-tooltip label="详细地址" width="200">
          </el-table-column>
          <el-table-column v-if="tableVal == 2" label="所属行业" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.industry">
                {{$getIndustry(scope.row.industry)}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="followStatus" label="跟进状态" width="200">
          </el-table-column>
          <el-table-column prop="modifiedTime" label="最后更改时间" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="remark" class-name="in-column" label="操作" fixed="right" align="center" width="140">
            <template slot-scope="scope">
              <div class="btn-box">
                <p @click="edit(scope.row)">查看</p>
                <p class="btn-p" @click="del(scope.row)">删除</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        :page-size="interPage"
        layout="prev, pager, next, jumper"
        :total="dataSize"
        background
      ></el-pagination>
    </div>
    <addClient v-else :info="examineObj"></addClient>
  </div>
</template>

<script>
import { core } from "../../config/pluginInit";
import addClient from './UiAlerts/addClient.vue';
import { getCustomer, delContact } from '@/FackApi/api/project.js';
import { Loading } from "element-ui";
export default {
  name: "UiAlerts",
  components: {
    addClient,
  },
  data() {
    return {
      currentPage: 1,
      interPage: 10,
      dataSize: 0,
      keyword: "",
      tableData: [],
      showTable: true,
      tableVal: 2,
      examineObj: {
        type: 'add',
        tableType: '', // 客户类型
      },
    };
  },
  created() {
    this.getCustomerInfo();
  },
  mounted() {
    core.index();
  },
  methods: {
    async getCustomerInfo() {
      this.tableData = [];
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `${this.currentPage}/${this.interPage}?type=${this.tableVal}`;
      if (this.keyword) {
        params += `&queryString=${this.keyword}`
      }
      let res = await getCustomer(params);
      // console.log(res);
      loadingInstance.close();
      if (res.code == 0) {
        this.tableData = res.items;
        this.dataSize = res.totalCount;
        this.$nextTick(()=> {
          // console.log('-----------');
          this.$refs.table.doLayout();
        })
      }
    },
    add() {
      this.examineObj.type = 'add';
      this.examineObj.tableType = this.tableVal;
      this.showTable = false;
    },
    // 回车键事件
    chekEnter() {
      this.interPage = 10;
      this.currentPage = 1;
      this.getCustomerInfo();
    },
    // 切换tab
    checkTable(idx) {
      this.interPage = 10;
      this.currentPage = 1;
      this.tableVal = idx;
      this.getCustomerInfo();
    },
    edit(row) {
      this.examineObj.type = 'examine';
      this.examineObj.tableType = this.tableVal;
      this.examineObj.info = row;
      this.showTable = false;
      // console.log(info);
    },
    del(row) {
      console.log(row);
      this.$confirm(`请确认是否删除客户【 ${row.name} 】`)
        .then((_) => {
          // done();
          this.delClient(row);
        })
        .catch((_) => {});
    },
    async delClient(row) {
      let params = {
        ids: [row.id]
      };
      let res2 = await delContact(params);
      // console.log(res2);
      if (res2.code == 0) {
        this.getCustomerInfo();
      }
    },
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getCustomerInfo();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getCustomerInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.Ui-badges {
  // width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 30px;
  margin: 0 15px;
  margin-top: 127px;
  border-radius: 18px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  .tabel-box {
    position: absolute;
    top: -110px;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #fff;
    border-radius: 18px;
    padding: 14px 22px 0;
    .p-box {
      display: flex;
      align-items: center;
      // border-top: 1px solid #E7E7E7;
      padding-top: 8px;
      p {
        width: 100px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        border-bottom: 6px solid #fff;
        margin-right: 10px;
        cursor:pointer;
      }
      .in-p {
        color: #837AF3;
        border-bottom: 6px solid #eae4ff;
      }
    }
  }
  /deep/ .top-box {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .el-button {
      width: 140px;
      height: 40px;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border-radius: 8px;
      border: 1px solid #bbd2ff;
      font-size: 14px;
      color: #fff;
      line-height: 16px;
    }
    .ipt-box {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .el-input {
        .el-input__inner {
          width: 300px;
          height: 40px;
          font-size: 14px;
          background: #eaf5ff;
          padding-left: 40px;
          border-radius: 10px;
          border: none;
        }
        .el-input__prefix {
          .el-input__icon {
            font-size: 22px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .form-box {
    flex: 1;
    /deep/ .el-table {
      border-radius: 18px;
      .el-table__header-wrapper {
        .el-table__header {
          .has-gutter {
            th {
              font-size: 14px;
              // font-weight: bold;
              color: #6474C7;
              padding: 12px 16px;
              background: #EAF5FF;
            }
            .in-column {
              color: #fff;
              background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            }
          }
        }
      }
      .el-table__body-wrapper {
        min-height: 380px;
        .el-table__body {
          td {
            font-size: 14px;
            color: #333;
            padding: 12px 16px;
            .cell {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
        }
      }
      .el-table__fixed-right {
        thead {
          .is-center {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%) !important;
          }
        }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
      }
    }
  }
  .el-pagination {
    align-items: initial;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
